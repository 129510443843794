import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  myTripsDetails: {},
  pnrDetails: {},
  checkinDetails: null,
  eReceiptDetails: {},
  isLoading: false,
  hasData: false,
  error: "",
  addMyTripResponse: "",
  isAddTripSuccess: false,
  addTriperror: "",
  pnrErrorMessage: ""
};
const fetchMyTripsSlice = createSlice({
  name: "myTrips",
  initialState,
  reducers: {
    myTripsDataRequest: (state, action) => {
      state.isLoading = true;
    },
    getMyTripsDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.myTripsDetails = action.payload;
    },
    getMyTripDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    myTripsPnrRequest: (state, action) => {
      state.isLoading = true;
    },
    getPnrDataSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.pnrDetails = action.payload;
      state.pnrErrorMessage = "";
    },
    getPnrDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearPnrData: state => {
      state.pnrDetails = {};
    },
    getPnrDataFailure1: (state, action) => {
      state.isLoading = false;
      state.pnrErrorMessage = action.payload;
    },
    resetTripDetails: state => {
      state.isLoading = false;
      state.myTripsDetails = {};
      state.hasData = false;
      state.error = "";
    },
    postAddTripPending: (state, action) => {
      state.isLoading = true;
      state.isAddTripSuccess = false;
      state.addTriperror = "";
    },
    postAddTripSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.addTriperror = "";
      state.hasData = true;
      state.isAddTripSuccess = true;
      state.addMyTripResponse = action.payload;
    },
    postAddTripFailure: (state, action) => {
      state.isLoading = false;
      state.isAddTripSuccess = false;
      state.error = action.payload.message;
      state.addTriperror = action.payload.response.data.message;
    },
    myTripsCheckinRequest: (state, action) => {
      state.isLoading = true;
    },
    postCheckInDataSuccess: (state, action) => {
      state.checkinDetails = action.payload;
      state.isLoading = false;
    },
    postCheckInDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetPnrDetails: state => {
      state.isLoading = false;
      state.pnrDetails = {};
      state.eReceiptDetails = {};
      state.checkinDetails = null;
      state.hasData = false;
      state.error = "";
    },
    retrieveTripsRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
    },
    retrieveTripsRequestSuccess: (state, action) => {
      const {
        payload,
        index
      } = action.payload;
      state.isLoading = false;
      state.error = false;
      state.myTripsDetails.pnrDetails[index].flightDetails = payload === null || payload === void 0 ? void 0 : payload.flightDetails;
      state.myTripsDetails.pnrDetails[index].segmentGroups = payload === null || payload === void 0 ? void 0 : payload.segmentGroups;
      state.myTripsDetails.pnrDetails[index].tripType = payload === null || payload === void 0 ? void 0 : payload.tripType;
    },
    retrieveTripsRequestFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setEReceiptState: (state, action) => {
      state.eReceiptDetails = action.payload;
    },
    myTripsDataRequestClaim: (state, action) => {
      state.isLoading = true;
    }
  }
});
export const {
  myTripsDataRequest,
  getMyTripsDataSuccess,
  getMyTripDataFailure,
  resetTripDetails,
  postAddTripPending,
  postAddTripSuccess,
  postAddTripFailure,
  myTripsPnrRequest,
  getPnrDataSuccess,
  getPnrDataFailure,
  getPnrDataFailure1,
  myTripsCheckinRequest,
  postCheckInDataSuccess,
  postCheckInDataFailure,
  resetPnrDetails,
  retrieveTripsRequest,
  retrieveTripsRequestFailure,
  retrieveTripsRequestSuccess,
  setEReceiptState,
  clearPnrData,
  myTripsDataRequestClaim
} = fetchMyTripsSlice.actions;
export default fetchMyTripsSlice.reducer;